export const best_window_management_tool_2024_08_25 = {
  date: "25 August 2024",
  title: "The Best Window Management Tool for Mac",
  description: "Discover the best window management tool for Mac and learn how it can help you boost productivity and efficiency.",
  keywords: [
    "Window Management Tool",
    "Mac",
    "Productivity",
    "Efficiency",
  ],
  body: `
  If you haven't experienced the superior computer experience by using a tiling or window manager, you should definitely start with one of the options below. It's 

## Rectangle

[Rectangle](https://rectangleapp.com) is a relatively well known Mac app that lets you manage your windows using simple shortcuts. The reason for its success is the ease with which a user can get to it - simply go to the website and download the installer file, install it and you're done.

I used it for a year or two, and at the time it seemed perfect. I could have multiple windows open on one screen, I could position these in a specific part of the screen, and I could pop out a window or fullscreen it in case the layout wasn't working for me.

But what if that positioning messed up the scaling, or I wanted to quickly switch between windows, or I want to move the window to my external monitor? It quickly started feeling restrictive, and I never fully learnt all the shortcuts for all the possible locations (I now know that could be simplified by repeating the key combinations, but oh well).

## Yabai

After watching many YouTube videos on dev workflows, keyboards and set ups, I stumbled on the combination of Yabai and Skhd. [Yabai](https://github.com/koekeishiya/yabai) is a window management tool but given that it's a developer tool it provides much more flexibility than Rectangle. To make full use of its features you will need some hot key daemon, like [Skhd](https://github.com/koekeishiya/skhd) (developed by the same person - \`koekeishiya\`).

Installation is fairly easy, if you've ever used Homebrew. 
\`\`\`bash
brew install koekeishiya/formulae/yabai
brew install koekeishiya/formulae/skhd
\`\`\`

You can then define your config files like \`./config/yabai/yabairc\` and \`./config/skhd/skhdrc\`. I will not go into detail on how these work and what each command can get you, but basically \`yabairc\` will contain rules around window management and \`skhdrc\` will contain the hotkeys to call yabai. My [dotfiles](https://github.com/feklisoff/dotfiles) are publicly available and you can look for example configurations there.

So why didn't I stop here? The best way I can describe it is small bugs here and there that simply interrupted my flow. It also has to be mentioned that to get the full functionality - ability of moving windows to other desktops, switching between desktops, etc. can only be achieved by disabling your mac's System Integrity Protection (SIP) settings (or at least partially, as per wiki). I'm sure you can agree disabling something called 'integrity protection' doesn't feel all that good, there's however a reason behind it - Apple doesn't allow you to mess with the Dock, which manages the desktops and some other window related features.
## Aerospace

So far my favourite mac window manager, which handles the Apple Dock issue discussed above by simply going around it and creating its own virtual desktops that most of the time stay hidden in some off screen corner. 
[Aerospace](https://github.com/nikitabobko/AeroSpace) is also a dev tool and currently in open beta, so installation will also be done via Homebrew.
\`\`\`bash
brew install --cask nikitabobko/tap/aerospace
\`\`\`
After create your config file in \`./config/aerospace/aerospace.toml\` and you can start by grabbing the default config (or the i3 config, if you're used to that) provided on the [website](https://nikitabobko.github.io/AeroSpace/guide#default-config). That's mostly it, just define your workspaces, where you want your windows to show up and which hotkeys you'd like to use.

## Conclusion
In the end, it seems all these options work (there's Amethyst also, I know), and you will have to decide which option works best in your case. But I would encourage everyone to try out a window manager to speed up the way they move around their computer. 
  `
}
