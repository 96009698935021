const INFO = {
	main: {
		title: "Aleksei Feklisov",
		name: "Aleksei Feklisov",
		email: "feklisoff@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/feklisoff",
		linkedin: "https://www.linkedin.com/in/afeklisov/",
	},

	homepage: {
		title: "Full-stack developer",
		description:
			"I am a backend developer currently working with Java, JS/TS, Node.js, Python and anything else that is thrown at me. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I got tired of LinkedIn, so this website was made to be a placeholder for my resume and more.",
	},

	about: {
		title: "I’m Aleksei, get to know me!",
		description:
			"Currently based in Vancouver, BC and working at AWS. Feel free to check out my projects or have a read through my articles.",
	},

	articles: {
		title: "I have thoughts sometimes, or maybe I need a cheat sheet",
		description:
			"This space is for any notes that I feel can be shared publicly.",
	},

	projects: [
		{
			title: "Bibliott.ca",
			description:
				"Find your reading speed and track your book reading progress in this simple web app. 📚",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://www.bibliott.ca",
		},

		{
			title: "GymBudBot",
			description:
				"A Telegram bot that lets you create workout routines and record your workout sets and reps. 💪",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://t.me/gymbudbot",
		},

    {
			title: "feklisoff.dev",
			description:
				"Yes, I am counting this website as a project, deal with it. 😎",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://feklisoff.dev",
		},
	],
};

export default INFO;
