import React from "react";
import { best_window_management_tool_2024_08_25 } from "./articles/august_2024"
import { marked } from "marked";

let defaultKeywords = ["Aleksei", "Aleksei Feklisov", "Feklisov", "feklisoff"];
const defaultStyle = `
  .article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
`;

const importedArticles = [best_window_management_tool_2024_08_25];

let articlesToExport = [];

function formatArticle(article) {
  return {
    date: article.date,
    title: article.title,
    description: article.description,
    keywords: defaultKeywords.concat(article.keywords),
    style: defaultStyle,
    body: (
			<React.Fragment>
				<div className="article-content" dangerouslySetInnerHTML={{ __html: marked(article.body) }}></div>
			</React.Fragment>
		),
  }
}

articlesToExport = [formatArticle(importedArticles[0])];

export default articlesToExport;
